/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import PageContentContainer from "../../Container/PageContentContainer";
import ControllingForm from "../../Controlling/ControllingForm";
import PartnerControllingForm from "../../Controlling/PartnerControllingForm";
import DatevMonthlyPaymentsForm from "../../Controlling/DatevMonthlyPaymentsForm";
import ContentBox from "../../ContentBox/ContentBox";
import PageHeadline from "../../PageHeadline/PageHeadline";

const ControllingPage = () => {
  return (
    <PageContentContainer>
      <PageHeadline main="Monatscontrolling" />
      <ContentBox headline={"Abrechnung erstellen"}>
        <ControllingForm />
      </ContentBox>
      <ContentBox headline={"Partnerabrechung erstellen"}>
        <PartnerControllingForm />
      </ContentBox>
      <ContentBox headline={"LBRA Zahlungen CSV für Datev erstellen"}>
        <DatevMonthlyPaymentsForm />
      </ContentBox>
    </PageContentContainer>
  );
};

export default ControllingPage;
