import * as stagesCase from "../../../Stages/StagesCase";
import { STAGE_ACTION_REQUIRED } from "../../../Stages/StagesCase";
import personFields from "../modules/personFields";
import {
  beaSafeIdFieldDefault,
  dateFieldDefault,
  emailDefault,
  faxNumberDefault,
  ibanFieldDefault,
  invoiceNetValueNotOverpaid,
  percentageFieldDefault,
  textFieldDefault,
} from "../../../validationRules";
import { trueFalseDefaultOptions } from "../formDefinitionFunctions";
import addressFields from "../modules/addressFields";
import InputAdornment from "@mui/material/InputAdornment";
import React from "react";
import { getProductSpecificDefinition, hasActiveCourtAppointment } from "../../ProductService";
import basicInsuranceFields from "../modules/basicInsuranceFields";
import _ from "lodash";
import { arrayToSelectOptions } from "../../../formServiceFunctions";
import { IbanInput } from "../../../../components/MaskedInputs/IbanInput";
import { allDataPageCourtFields } from "../allDataPageCourtFields";
import { getCommonContractLawCaseRejectionCategorySelectOptions } from "./contractLawFormHelperService";
import { AbstractCase } from "../../../../types/AbstractCase";
import { objectivesLawFirm } from "../modules/objectiveLawfirmValues";
import { FormElementPropsWithoutProduct } from "../../../../components/Case/CaseForm/FormElement";
import { UseForm } from "../../../../types/UseForm";
import { BackofficeUser, Roles } from "../../../../types/BackofficeUser";
import { userHasOneOfTheseRoles } from "../../../backofficeUserService";
import DateChangeButtonsToolbar from "../../../../components/Validator/DateChangeButtonsToolbar";

export const fullFormDefinition = (product: AbstractCase, currentUser: BackofficeUser) => {
  const contractBaseCreated = {
    isHidden: ({ product }: { product: AbstractCase }) => product.stage! > stagesCase.STAGE_CREATED,
    label: "Allgemeines zum Vertrag",
    elements: [...personFields({ path: `${product.productClassName}.client`, showDiverseGenderOption: true })],
  };

  const contractBase = {
    isHidden: ({ product }: { product: AbstractCase }) => product.stage! < stagesCase.STAGE_DATA_COMPLETED,
    label: "Allgemeines zum Vertrag",
    elements: [
      {
        type: "ValidatorSelect",
        path: `${product.productClassName}.caseCategory`,
        isDisabled: ({ product }: { product: AbstractCase }) =>
          product.stage! > STAGE_ACTION_REQUIRED || product.caseCategory === "advisory",
        options: ({ product }: { product: AbstractCase }) => {
          return product.caseCategory === "advisory"
            ? [{ value: "enforceClaim" }, { value: "defendClaim" }, { value: "advisory" }]
            : [{ value: "enforceClaim" }, { value: "defendClaim" }];
        },
        alert: {
          onChange:
            "Das Ändern der Position des Mandanten (Kläger/Beklagter) führt dazu, dass sich viele " +
            "Felder ändern und auch Felder verschwinden. Die neuen Felder müssen dann von Ihnen neu " +
            "befüllt werden, damit der Fall weiter bearbeitet werden kann. Möchten Sie die Position " +
            "des Mandanten im Verfahren tatsächlich ändern?",
        },
      },
      {
        type: "ValidatorSelect",
        path: `${product.productClassName}.contractorTypeClient`,
        isDisabled: () => true,
        options: () => [{ value: "customer" }, { value: "coinsured" }, { value: "unknown" }],
      },
      {
        type: "ValidatorDateField",
        path: `${product.productClassName}.contractDate`,
        validators: dateFieldDefault,
        isDisabled: ({ product }: { product: AbstractCase }) => product.stage! >= stagesCase.STAGE_LAWSUIT,
        isHidden: ({ product }: { product: AbstractCase }) => product.advisoryHandling === true,
      },
      ...personFields({ path: `${product.productClassName}.client`, showDiverseGenderOption: true }),
      ...addressFields({
        path: `${product.productClassName}.client.residenceAddress`,
        isHidden: ({ product }: { product: AbstractCase }) => product.advisoryHandling === true,
      }),
      {
        type: "ValidatorDateField",
        path: `${product.productClassName}.client.birthDate`,
        validators: dateFieldDefault,
        isDisabled: ({ product }: { product: AbstractCase }) => product.stage! >= stagesCase.STAGE_CHECKED,
      },
      {
        type: "ValidatorSelect",
        path: `${product.productClassName}.objectiveClient`,
        isDisabled: () => true,
        isHidden: ({ product }: { product: AbstractCase }) => product.caseCategory !== "enforceClaim",
        options: () => [
          { value: "compensation" },
          { value: "reduction" },
          { value: "replacement" },
          { value: "repair" },
          { value: "withdrawal" },
          { value: "fulfillment" },
          { value: "other" },
        ],
      },
      {
        type: "ValidatorSelect",
        path: `${product.productClassName}.subjectOfDispute`,
        translationPath: `${product.productClassName}.subjectOfDispute.${product.caseCategory}`,
        isDisabled: ({ product }: { product: AbstractCase }) => product.stage! >= stagesCase.STAGE_CHECKED,
        isHidden: ({ product }: { product: AbstractCase }) => product.advisoryHandling === true,
        options: () => [{ value: "money" }, { value: "goods" }, { value: "other" }],
      },
      {
        type: "ValidatorSelect",
        path: `${product.productClassName}.clientHasAlreadyClaimed`,
        isDisabled: ({ product }: { product: AbstractCase }) => product.stage! >= stagesCase.STAGE_CHECKED,
        isHidden: ({ product }: { product: AbstractCase }) => product.caseCategory !== "enforceClaim",
        options: () => trueFalseDefaultOptions(`${product.productClassName}.clientHasAlreadyClaimed`),
      },
      {
        type: "ValidatorDateField",
        path: `${product.productClassName}.claimedDateClient`,
        validators: dateFieldDefault,
        isHidden: ({ product }: { product: AbstractCase }) => product.caseCategory !== "enforceClaim",
      },
      {
        type: "ValidatorDateField",
        path: `${product.productClassName}.claimedDeadlineClient`,
        validators: dateFieldDefault,
        isHidden: ({ product }: { product: AbstractCase }) =>
          product.caseCategory !== "enforceClaim" || !product.clientHasAlreadyClaimed,
      },
      {
        type: "ValidatorNumberField",
        path: `${product.productClassName}.claimedAmountClient`,
        isHidden: ({ product }: { product: AbstractCase }) => product.subjectOfDispute !== "money",
        numberType: "float",
        fieldType: "money",
      },
      {
        type: "ValidatorNumberField",
        path: `${product.productClassName}.overdueFees`,
        isHidden: ({ product }: { product: AbstractCase }) =>
          product.subjectOfDispute !== "money" ||
          (product.caseCategory !== "defendClaim" && !product.clientHasAlreadyClaimed),
        fieldType: "money",
        numberType: "float",
      },
      {
        type: "ValidatorTextField",
        path: `${product.productClassName}.clientBankData.iban`,
        validators: ibanFieldDefault,
        additionalProps: {
          InputProps: {
            inputComponent: IbanInput,
            startAdornment: <InputAdornment position="start">DE</InputAdornment>,
          },
        },
      },
      {
        type: "ValidatorSelect",
        path: `${product.productClassName}.defenseProcessStatus`,
        isDisabled: ({ product }: { product: AbstractCase }) =>
          product.stage! >= stagesCase.STAGE_DATA_COMPLETED_LAWFIRM,
        isHidden: ({ product }: { product: AbstractCase }) => product.caseCategory !== "defendClaim",
        options: () => [
          { value: "reminder" },
          { value: "debtCollection" },
          { value: "paymentOrder" },
          { value: "enforcementOrder" },
          { value: "lawsuit" },
          { value: "none" },
        ],
      },
    ],
  };

  const contractDetails = {
    isHidden: ({ product }: { product: AbstractCase }) =>
      product.stage! < stagesCase.STAGE_DATA_COMPLETED || product.caseCategory === "advisory",
    label: "Details zum Vertrag",
    elements: getProductSpecificDefinition(product),
  };

  const contractPartner = {
    isHidden: ({ product }: { product: AbstractCase }) =>
      product.stage! < stagesCase.STAGE_DATA_COMPLETED || product.caseCategory === "advisory",
    label: "Gegenseite",
    elements: [
      {
        type: "ValidatorSelect",
        path: `${product.productClassName}.opposingPartyType`,
        isDisabled: ({ product }: { product: AbstractCase }) => product.stage! >= stagesCase.STAGE_COMPLETED,
        options: () => [{ value: "opposingPartyLegalEntity" }, { value: "opposingPartyPerson" }],
        additionalProps: {
          fullWidth: true,
        },
      },
      {
        type: "ContractualPartnerLegalEntityPicker",
        path: `${product.productClassName}.contractualPartnerLegalEntity`,
        isHidden: ({ values }: { values: UseForm["values"] }) =>
          values[product.productClassName].opposingPartyType !== "opposingPartyLegalEntity",
        additionalProps: {
          fullWidth: true,
        },
      },
      ...personFields({
        path: `${product.productClassName}.contractualPartnerPerson`,
        isHidden: ({ values }: { values: UseForm["values"] }) =>
          values[product.productClassName].opposingPartyType !== "opposingPartyPerson",
        isDisabled: ({ product }: { product: AbstractCase }) => product.stage! >= stagesCase.STAGE_COMPLETED,
      }),
      {
        type: "ValidatorTextField",
        path: `${product.productClassName}.contractualPartnerPerson.email`,
        validators: emailDefault,
        isDisabled: ({ product }: { product: AbstractCase }) => product.stage! >= stagesCase.STAGE_COMPLETED,
        isHidden: ({ values }: { values: UseForm["values"] }) =>
          values[product.productClassName].opposingPartyType !== "opposingPartyPerson",
      },
      {
        type: "ValidatorTextField",
        path: `${product.productClassName}.contractualPartnerPerson.telephone`,
        isDisabled: ({ product }: { product: AbstractCase }) => product.stage! >= stagesCase.STAGE_COMPLETED,
        isHidden: ({ values }: { values: UseForm["values"] }) =>
          values[product.productClassName].opposingPartyType !== "opposingPartyPerson",
      },
      ...addressFields({
        path: `${product.productClassName}.contractualPartnerPerson.residenceAddress`,
        isHidden: ({ values }: { values: UseForm["values"] }) =>
          values[product.productClassName].opposingPartyType !== "opposingPartyPerson",
        isDisabled: ({ product }: { product: AbstractCase }) => product.stage! >= stagesCase.STAGE_COMPLETED,
      }),
    ],
  };

  const prosecution = {
    label: "Staatsanwaltschaft",
    isHidden: ({ product }: { product: AbstractCase }) =>
      product.stage! < stagesCase.STAGE_DATA_COMPLETED || product.advisoryHandling === true,
    elements: [
      {
        type: "ProsecutionPicker",
        path: `${product.productClassName}.responsibleProsecution`,
        additionalProps: {
          fullWidth: true,
        },
      },
      {
        type: "ValidatorTextField",
        path: `${product.productClassName}.prosecutionReference`,
        validators: textFieldDefault,
        additionalProps: {
          fullWidth: true,
        },
      },
    ],
  };

  const debtCollection = {
    isHidden: ({ product }: { product: AbstractCase }) =>
      product.stage! < stagesCase.STAGE_DATA_COMPLETED ||
      !_.includes(["defendClaim", "enforceClaim"], product.caseCategory),
    label: "Inkassobüro",
    elements: [
      {
        type: "DebtCollectionAgencyPicker",
        path: `${product.productClassName}.debtCollectionAgency`,
        isDisabled: ({ product }: { product: AbstractCase }) => product.stage! >= stagesCase.STAGE_COMPLETED,
        additionalProps: {
          fullWidth: true,
        },
      },
      {
        type: "ValidatorTextField",
        path: `${product.productClassName}.debtCollectionAgencyReference`,
      },
    ],
  };

  const opponentLawyer = {
    label: "Anwalt Gegenseite",
    isHidden: ({ product }: { product: AbstractCase }) =>
      (product.stage || 0) < stagesCase.STAGE_DATA_COMPLETED || product.advisoryHandling === true,
    elements: [
      {
        type: "BeaLawyerImportButton",
        path: "pathPropNotUsedButRequired",
        additionalProps: {
          fullWidth: true,
        },
      },
      ...personFields({ path: `${product.productClassName}.opponentLawyer` }),
      {
        type: "ValidatorTextField",
        path: `${product.productClassName}.opponentLawFirm.name`,
      },
      {
        type: "ValidatorTextField",
        path: `${product.productClassName}.opponentLawFirm.email`,
        validators: emailDefault,
      },
      {
        type: "ValidatorTextField",
        path: `${product.productClassName}.opponentLawFirm.telephone`,
      },
      {
        type: "ValidatorTextField",
        path: `${product.productClassName}.opponentLawFirm.fax`,
        validators: faxNumberDefault,
        additionalProps: {
          InputProps: {
            startAdornment: <InputAdornment position="start">+49</InputAdornment>,
          },
        },
      },
      {
        type: "ValidatorTextField",
        path: `${product.productClassName}.opponentLawFirm.beaSafeId`,
        validators: beaSafeIdFieldDefault,
      },
      ...addressFields({ path: `${product.productClassName}.opponentLawFirm.address` }),
      {
        type: "ValidatorTextField",
        path: `${product.productClassName}.referenceOpponentLawyer`,
      },
    ],
  };

  const contractDescription = {
    isHidden: ({ product }: { product: AbstractCase }) => product.stage! < stagesCase.STAGE_DATA_COMPLETED,
    label: "Beschreibung der Situation",
    elements: [
      {
        type: "ValidatorTextField",
        path: `${product.productClassName}.personalTextMessage`,
        isDisabled: () => true,
        additionalProps: {
          multiline: true,
          fullWidth: true,
        },
      },
    ],
  };

  const contractInsurance = {
    label: "Versicherung",
    elements: [
      ...basicInsuranceFields({
        path: `${product.productClassName}.insurance`,
        product: product,
      }),
      {
        type: "ValidatorNumberField",
        path: `${product.productClassName}.insurance.deductible`,
        fieldType: "money",
        numberType: "float",
      },
      {
        type: "ValidatorDateField",
        path: `${product.productClassName}.insurance.extrajudicialCoverageRequestedDate`,
        validators: dateFieldDefault,
      },
      {
        type: "ValidatorDateField",
        path: `${product.productClassName}.insurance.extrajudicialCoverageRequestAnsweredDate`,
        validators: dateFieldDefault,
      },
      {
        type: "ValidatorSelect",
        path: `${product.productClassName}.insurance.extrajudicialCoverageGranted`,
        options: () => trueFalseDefaultOptions(`${product.productClassName}.extrajudicialCoverageGranted`),
      },
      {
        type: "ValidatorDateField",
        path: `${product.productClassName}.insurance.judicialCoverageRequestedDate`,
        validators: dateFieldDefault,
      },
      {
        type: "ValidatorDateField",
        path: `${product.productClassName}.insurance.judicialCoverageRequestAnsweredDate`,
        validators: dateFieldDefault,
      },
      {
        type: "ValidatorSelect",
        path: `${product.productClassName}.insurance.judicialCoverageGranted`,
        options: () => trueFalseDefaultOptions(`${product.productClassName}.extrajudicialCoverageGranted`),
      },
    ],
  };

  const processParticipantsPeople = {
    label: "Personen",
    isHidden: ({ product }: { product: AbstractCase }) => product.productCategory !== "contractLaw",
    elements: [
      {
        type: "ProcessParticipantsPeopleForm",
        path: `${product.productClassName}.relatedPeople`,
        additionalProps: {
          fullWidth: true,
        },
      },
    ],
  };

  const processParticipantsOrganizations = {
    label: "Unternehmen",
    isHidden: ({ product }: { product: AbstractCase }) => product.productCategory !== "contractLaw",
    elements: [
      {
        type: "ProcessParticipantsOrganizationsForm",
        path: `${product.productClassName}.relatedOrganizations`,
        additionalProps: {
          fullWidth: true,
        },
      },
    ],
  };

  return {
    product: {
      sections: [
        contractBaseCreated,
        contractBase,
        contractDetails,
        contractDescription,
        getAdditionalClientsForm(product),
        getContractUnderageClientForm(product),
      ],
    },
    additional: {
      sections: [
        {
          label: "Allgemeines",
          elements: [
            {
              type: "ValidatorTextField",
              path: `${product.productClassName}.reference`,
              isDisabled: () => true,
            },
            {
              type: "ValidatorTextField",
              path: `${product.productClassName}.powerOfAttorney`,
              isHidden: ({ product }: { product: AbstractCase }) =>
                product.stage! < stagesCase.STAGE_DATA_COMPLETED || product.caseCategory === "advisory",
              isDisabled: () => true,
            },
            {
              type: "ValidatorSelect",
              path: `${product.productClassName}.objectiveLawFirm`,
              isDisabled: ({ product }: { product: AbstractCase }) => product.stage! >= stagesCase.STAGE_CHECKED,
              isHidden: ({ product }: { product: AbstractCase }) => product.advisoryHandling === true,
              options: ({ product }: { product: AbstractCase }) => {
                if (_.isEmpty(product.possibleObjectives)) {
                  return objectivesLawFirm;
                } else {
                  return arrayToSelectOptions(product.possibleObjectives);
                }
              },
            },
            {
              type: "ValidatorSelect",
              path: `${product.productClassName}.objectiveLawFirmSecondary`,
              isDisabled: ({ product }: { product: AbstractCase }) => product.stage! >= stagesCase.STAGE_CHECKED,
              isHidden: ({ product }: { product: AbstractCase }) => product.advisoryHandling === true,
              options: ({ product }: { product: AbstractCase }) => {
                if (_.isEmpty(product.possibleObjectives)) {
                  return objectivesLawFirm;
                } else {
                  return arrayToSelectOptions(product.possibleObjectives);
                }
              },
            },
            {
              type: "ValidatorSelect",
              path: `${product.productClassName}.objectiveLawFirmTertiary`,
              isDisabled: ({ product }: { product: AbstractCase }) => product.stage! >= stagesCase.STAGE_CHECKED,
              isHidden: ({ product }: { product: AbstractCase }) => product.advisoryHandling === true,
              options: ({ product }: { product: AbstractCase }) => {
                if (_.isEmpty(product.possibleObjectives)) {
                  return objectivesLawFirm;
                } else {
                  return arrayToSelectOptions(product.possibleObjectives);
                }
              },
            },
            {
              type: "ValidatorDateField",
              path: `${product.productClassName}.timeBarredDate`,
              validators: dateFieldDefault,
              isHidden: ({ product }: { product: AbstractCase }) =>
                product.stage! < stagesCase.STAGE_DATA_COMPLETED || product.advisoryHandling === true,
              isDisabled: ({ product }: { product: AbstractCase }) => product.stage! >= stagesCase.STAGE_CHECKED,
            },
            {
              type: "ValidatorSelect",
              path: `${product.productClassName}.caseCategoryAssessment`,
              isHidden: ({ product }: { product: AbstractCase }) =>
                product.stage! < stagesCase.STAGE_DATA_COMPLETED || product.caseCategory === "advisory",
              isDisabled: ({ product }: { product: AbstractCase }) =>
                product.stage! > stagesCase.STAGE_WAITING_FOR_FEEDBACK,
              options: () => [{ value: "confirm" }, { value: "reject" }],
            },
          ],
        },
        {
          label: "Außergerichtliches Verfahren",
          isHidden: ({ product }: { product: AbstractCase }) =>
            product.stage! < stagesCase.STAGE_DATA_COMPLETED ||
            product.caseCategory === "advisory" ||
            product.previousInstanceCase,
          elements: [
            {
              type: "CaseRejectionCategorySelect",
              path: `${product.productClassName}.caseRejectionCategory`,
              options: getCommonContractLawCaseRejectionCategorySelectOptions,
              isDisabled: ({ product }: { product: AbstractCase }) => product.stage! >= stagesCase.STAGE_CHECKED,
              isHidden: ({ product, values }: { product: AbstractCase; values: UseForm["values"] }) =>
                values[product.productClassName].caseCategoryAssessment !== "reject",
            },
            {
              type: "ValidatorTextField",
              path: `${product.productClassName}.assessmentRejectionText`,
              isDisabled: ({ product }: { product: AbstractCase }) => product.stage! >= stagesCase.STAGE_CHECKED,
              isHidden: ({ product, values }: { product: AbstractCase; values: UseForm["values"] }) =>
                values[product.productClassName].caseCategoryAssessment !== "reject",
              additionalProps: {
                multiline: true,
                rows: 12,
                fullWidth: true,
              },
            },
            {
              type: "ValidatorTextField",
              path: `${product.productClassName}.additionalIssueDescriptionText`,
              isDisabled: ({ product }: { product: AbstractCase }) => product.stage! >= stagesCase.STAGE_CHECKED,
              isHidden: ({ product }: { product: AbstractCase }) =>
                product.advisoryHandling === true ||
                product.isLawsuitHandling ||
                (product.stage! >= stagesCase.STAGE_CHECKED && !product.additionalIssueDescriptionText),
              additionalProps: {
                multiline: true,
                fullWidth: true,
              },
            },
            {
              type: "ValidatorTextField",
              path: `${product.productClassName}.additionalClaimText`,
              isDisabled: ({ product }: { product: AbstractCase }) => product.stage! >= stagesCase.STAGE_CHECKED,
              isHidden: ({ product }: { product: AbstractCase }) =>
                product.advisoryHandling === true ||
                product.isLawsuitHandling ||
                (product.stage! >= stagesCase.STAGE_CHECKED && !product.additionalClaimText),
              additionalProps: {
                multiline: true,
                fullWidth: true,
              },
            },
            {
              type: "ValidatorTextField",
              path: `${product.productClassName}.defectsDescriptionLawFirm`,
              isDisabled: ({ product }: { product: AbstractCase }) => product.stage! >= stagesCase.STAGE_CHECKED,
              isHidden: ({ product }: { product: AbstractCase }) =>
                !_.has(product, "defectsDescriptionLawFirm") || product.caseCategory !== "enforceClaim",
              additionalProps: {
                multiline: true,
                fullWidth: true,
              },
            },
            {
              type: "ValidatorDateField",
              path: `${product.productClassName}.extrajudicialCommunicationSent`,
              validators: dateFieldDefault,
              isDisabled: ({ product }: { product: AbstractCase }) =>
                product.stage! < stagesCase.STAGE_CHECKED ||
                product.stage! >= stagesCase.STAGE_EXTRAJUDICIAL_COMMUNICATION_SENT,
              isHidden: ({ product }: { product: AbstractCase }) => product.advisoryHandling === true,
            },
            {
              type: "ValidatorDateField",
              path: `${product.productClassName}.extrajudicialCommunicationDeadline`,
              validators: dateFieldDefault,
              isDisabled: ({ product }: { product: AbstractCase }) =>
                product.stage! < stagesCase.STAGE_DATA_COMPLETED || product.stage! >= stagesCase.STAGE_LAWSUIT,
              isHidden: ({ product }: { product: AbstractCase }) => product.advisoryHandling === true,
              additionalProps: {
                toolbar: DateChangeButtonsToolbar,
              },
            },
            {
              type: "ValidatorNumberField",
              path: `${product.productClassName}.claimedAmountLawFirm`,
              isDisabled: ({ product }: { product: AbstractCase }) => product.stage! >= stagesCase.STAGE_CHECKED,
              fieldType: "money",
              numberType: "float",
            },
            {
              type: "ValidatorDateField",
              path: `${product.productClassName}.extrajudicialReactionReceived`,
              validators: dateFieldDefault,
              isDisabled: ({ product }: { product: AbstractCase }) =>
                product.extrajudicialReaction === "noReaction" ||
                product.stage! < stagesCase.STAGE_EXTRAJUDICIAL_COMMUNICATION_SENT ||
                product.stage! >= stagesCase.STAGE_EXTRAJUDICIAL_REACTION_RECEIVED,
            },
            {
              type: "ValidatorSelect",
              path: `${product.productClassName}.extrajudicialReaction`,
              translationPath: `${product.productClassName}.extrajudicialReaction.${product.caseCategory}`,
              isDisabled: ({ product }: { product: AbstractCase }) =>
                product.stage! < stagesCase.STAGE_EXTRAJUDICIAL_COMMUNICATION_SENT ||
                product.stage! >= stagesCase.STAGE_EXTRAJUDICIAL_REACTION_RECEIVED,
              options: () => [
                { value: "noReaction" },
                { value: "fullyAgreed" },
                { value: "partiallyAgreed" },
                { value: "disagreed" },
              ],
            },
            {
              type: "ValidatorSelect",
              path: `${product.productClassName}.hasMonitoringBilling`,
              isHidden: ({ product }: { product: AbstractCase }) => !_.includes([2], product.insurance.insurance.id),
              options: () => trueFalseDefaultOptions(`${product.productClassName}.hasMonitoringBilling`),
              isDisabled: ({ product }: { product: AbstractCase }) =>
                product.stage! < stagesCase.STAGE_CHECKED ||
                (product.stage! === stagesCase.STAGE_COMPLETED &&
                  !userHasOneOfTheseRoles(currentUser, [Roles.accounting, Roles.admin])),
            },
            {
              type: "ValidatorTextField",
              path: `${product.productClassName}.opposingPartyReference`,
            },
            {
              type: "ValidatorSelect",
              path: `${product.productClassName}.extrajudicialRecommendation`,
              isDisabled: ({ product }: { product: AbstractCase }) =>
                product.stage! !== stagesCase.STAGE_EXTRAJUDICIAL_REACTION_RECEIVED &&
                product.stage! !== stagesCase.STAGE_EXTRAJUDICIAL_COMMUNICATION_SENT,
              isHidden: ({ product }: { product: AbstractCase }) =>
                !_.includes(["defendClaim", "enforceClaim"], product.caseCategory),
              options: ({ product }: { product: AbstractCase }) =>
                product.caseCategory === "defendClaim"
                  ? [
                      { value: "agreementDone" },
                      { value: "insufficientEvidence" },
                      { value: "insufficientChancesOfSuccess" },
                      { value: "costBenefitConsiderations" },
                      { value: "lawsuit" },
                      { value: "individualText" },
                    ]
                  : [
                      { value: "agreementDone" },
                      { value: "insufficientEvidence" },
                      { value: "insufficientChancesOfSuccess" },
                      { value: "costBenefitConsiderations" },
                      { value: "anonymousOpponent" },
                      { value: "opponentUnableToPay" },
                      { value: "lawsuit" },
                      { value: "individualText" },
                    ],
            },
            {
              type: "ValidatorTextField",
              path: `${product.productClassName}.extrajudicialRecommendationText`,
              isDisabled: ({ product }: { product: AbstractCase }) =>
                !(
                  product.stage! === stagesCase.STAGE_EXTRAJUDICIAL_COMMUNICATION_SENT ||
                  (product.stage! === stagesCase.STAGE_EXTRAJUDICIAL_REACTION_RECEIVED &&
                    product.extrajudicialRecommendationText)
                ),
              isHidden: ({ product }: { product: AbstractCase }) =>
                !_.includes(["defendClaim", "enforceClaim"], product.caseCategory) ||
                (product.stage! > stagesCase.STAGE_EXTRAJUDICIAL_REACTION_RECEIVED &&
                  !product.extrajudicialRecommendationText),
              additionalProps: {
                multiline: true,
                fullWidth: true,
              },
            },
            {
              type: "ValidatorSelect",
              path: `${product.productClassName}.finalClaimStatusClient`, // Forderungsstatus nach Fristende aus Mandantensicht
              translationPath: `${product.productClassName}.finalClaimStatusClient.${product.caseCategory}`, // Forderungsstatus nach Fristende aus Mandantensicht
              helperText:
                "Achtung: Feld ist über Servicewelt durch Mandantschaft zu befüllen! Wenn dieses Feld von uns befüllt wird, " +
                "kann die Mandantschaft kein Feedback mehr abgeben. Daher nur befüllen, wenn mit Mandantschaft explizit abgesprochen.",
              isDisabled: ({ product }: { product: AbstractCase }) =>
                product.stage! > stagesCase.STAGE_EXTRAJUDICIAL_REACTION_RECEIVED,
              isHidden: ({ product }: { product: AbstractCase }) => product.advisoryHandling === true,
              options: () => [
                { value: "fullyAgreed" },
                { value: "partiallyAgreed" },
                { value: "notAgreed" },
                { value: "noReaction" },
              ],
            },
            {
              type: "ValidatorSelect",
              path: `${product.productClassName}.finalProcessFeedbackClient`, // Rückmeldung Mandant
              translationPath: `${product.productClassName}.finalProcessFeedbackClient.${product.caseCategory}`, // Rückmeldung Mandant
              helperText:
                "Achtung: Feld ist über Servicewelt durch Mandantschaft zu befüllen! Wenn dieses Feld von uns befüllt wird, " +
                "kann die Mandantschaft kein Feedback mehr abgeben. Daher nur befüllen, wenn mit Mandantschaft explizit abgesprochen.",
              isDisabled: ({ product }: { product: AbstractCase }) =>
                product.stage! > stagesCase.STAGE_EXTRAJUDICIAL_REACTION_RECEIVED,
              isHidden: ({ product }: { product: AbstractCase }) =>
                product.advisoryHandling === true || product.finalClaimStatusClient === "fullyAgreed",
              options: () => [{ value: "agreement" }, { value: "lawsuit" }],
            },
            {
              type: "ValidatorDateField",
              path: `${product.productClassName}.statementOfClaimSentDate`,
              validators: dateFieldDefault,
              isHidden: ({ product }: { product: AbstractCase }) => product.caseCategory !== "enforceClaim",
            },
          ],
        },
        {
          label: "Gerichtliches Verfahren",
          isHidden: ({ product }: { product: AbstractCase }) =>
            product.stage! < stagesCase.STAGE_DATA_COMPLETED || product.advisoryHandling === true,
          elements: [
            {
              type: "ValidatorDateField",
              path: `${product.productClassName}.paymentOrderDate`,
              validators: dateFieldDefault,
              isDisabled: ({ product }: { product: AbstractCase }) =>
                product.stage! >= stagesCase.STAGE_LAWSUIT_COMPLETED,
              isHidden: ({ product }: { product: AbstractCase }) => product.caseCategory !== "defendClaim",
            },
            {
              type: "ValidatorDateField",
              path: `${product.productClassName}.enforcementOrderDate`,
              validators: dateFieldDefault,
              isDisabled: ({ product }: { product: AbstractCase }) =>
                product.stage! >= stagesCase.STAGE_LAWSUIT_COMPLETED,
              isHidden: ({ product }: { product: AbstractCase }) => product.caseCategory !== "defendClaim",
            },
            {
              type: "ValidatorDateField",
              path: `${product.productClassName}.statementOfClaimFiledDate`,
              validators: dateFieldDefault,
              isDisabled: ({ product }: { product: AbstractCase }) =>
                product.stage! >= stagesCase.STAGE_LAWSUIT_COMPLETED,
              isHidden: ({ product }: { product: AbstractCase }) => product.caseCategory !== "defendClaim",
            },
            {
              type: "ValidatorDateField",
              path: `${product.productClassName}.statementOfDefenseFiledDate`,
              validators: dateFieldDefault,
              isDisabled: ({ product }: { product: AbstractCase }) => product.stage! !== stagesCase.STAGE_LAWSUIT,
              isHidden: ({ product }: { product: AbstractCase }) =>
                product.caseCategory !== "enforceClaim" || product.lawsuitCompletedReason === "lawsuitRevocation",
            },
            {
              type: "ValidatorDateField",
              path: `${product.productClassName}.replicaSentDate`,
              validators: dateFieldDefault,
              isDisabled: ({ product }: { product: AbstractCase }) => product.stage! !== stagesCase.STAGE_LAWSUIT,
              isHidden: ({ product }: { product: AbstractCase }) =>
                product.caseCategory !== "enforceClaim" || product.lawsuitCompletedReason === "lawsuitRevocation",
            },
            {
              type: "ValidatorTextField",
              path: `${product.productClassName}.courtPostalCode`,
              helperText:
                "Bitte hier die Postleitzahl eintragen, die für den Gerichtsstand ermittelt wurde und NICHT die Postleitzahl des Gerichtes.",
              isHidden: ({ product }: { product: AbstractCase }) =>
                product.stage! > stagesCase.STAGE_LAWSUIT ||
                product.caseCategory === "defendClaim" ||
                product.isHigherInstanceCase,
              alert: {
                onChange:
                  "Das Ändern der Postleitzahl führt dazu, dass das zuständige Gericht erneut ermittelt wird. Möchten Sie die Postleitzahl tatsächlich ändern?",
              },
            },
            {
              type: "ValidatorTextField",
              path: `${product.productClassName}.courtReference`,
              isHidden: ({ product }: { product: AbstractCase }) =>
                !_.includes(["defendClaim", "enforceClaim"], product.caseCategory),
            },
            {
              type: "ValidatorDateField",
              path: `${product.productClassName}.statementOfDefenseSentDate`,
              validators: dateFieldDefault,
              isDisabled: ({ product }: { product: AbstractCase }) => product.stage! !== stagesCase.STAGE_LAWSUIT,
              isHidden: ({ product }: { product: AbstractCase }) =>
                product.caseCategory !== "defendClaim" || product.lawsuitCompletedReason === "lawsuitRevocation",
            },
            {
              type: "ValidatorDateField",
              path: `${product.productClassName}.replicaFiledDate`,
              validators: dateFieldDefault,
              isDisabled: ({ product }: { product: AbstractCase }) => product.stage! !== stagesCase.STAGE_LAWSUIT,
              isHidden: ({ product }: { product: AbstractCase }) =>
                product.caseCategory !== "defendClaim" || product.lawsuitCompletedReason === "lawsuitRevocation",
            },
            {
              type: "ValidatorSelect",
              path: `${product.productClassName}.lawsuitCompletedReason`,
              isDisabled: ({ product }: { product: AbstractCase }) => product.stage! !== stagesCase.STAGE_LAWSUIT,
              isHidden: ({ product }: { product: AbstractCase }) => product.advisoryHandling === true,
              options: () => [
                { value: "courtDecision" },
                { value: "settlement" },
                { value: "lawsuitRevocation" },
                { value: "resolution" },
                { value: "defaultJudgement" },
                { value: "judgementAppeal" },
              ],
            },
            {
              type: "ValidatorDateField",
              path: `${product.productClassName}.customerLawsuitCompletedApprovalDate`,
              translationPath: ({ values }: { values: UseForm["values"] }) =>
                `${product.productClassName}.customerLawsuitCompletedApprovalDate.${
                  values[product.productClassName].lawsuitCompletedReason
                }`,
              validators: dateFieldDefault,
              isDisabled: ({ product }: { product: AbstractCase }) => product.stage! > stagesCase.STAGE_LAWSUIT,
              isHidden: ({ values }: { values: UseForm["values"] }) =>
                _.includes(
                  ["courtDecision", "defaultJudgement", "judgementAppeal"],
                  values[product.productClassName].lawsuitCompletedReason
                ) || !values[product.productClassName].lawsuitCompletedReason,
            },
            {
              type: "ValidatorDateField",
              path: `${product.productClassName}.lawsuitCompletedDate`,
              validators: dateFieldDefault,
              isDisabled: ({ product }: { product: AbstractCase }) => product.stage! > stagesCase.STAGE_LAWSUIT,
              isHidden: ({ values }: { values: UseForm["values"] }) =>
                !values[product.productClassName].lawsuitCompletedReason,
            },
            {
              type: "ValidatorDateField",
              path: `${product.productClassName}.judgementReceivedDate`,
              validators: dateFieldDefault,
              isDisabled: ({ product }: { product: AbstractCase }) =>
                product.stage! >= stagesCase.STAGE_LAWSUIT_COMPLETED,
              isHidden: ({ product }: { product: AbstractCase }) => product.stage! < stagesCase.STAGE_LAWSUIT,
            },
            {
              type: "ValidatorNumberField",
              path: `${product.productClassName}.valueInDisputePreliminary`,
              isDisabled: ({ product }: { product: AbstractCase }) => product.stage! >= stagesCase.STAGE_LAWSUIT,
              fieldType: "money",
              numberType: "float",
            },
            {
              type: "ValidatorNumberField",
              path: `${product.productClassName}.valueInDispute`,
              isDisabled: ({ product }: { product: AbstractCase }) => product.stage! != stagesCase.STAGE_TRIAL,
              fieldType: "money",
              numberType: "float",
            },
            {
              type: "ValidatorSelect",
              path: `${product.productClassName}.hasStandardInvoicing`,
              options: () => trueFalseDefaultOptions(`${product.productClassName}.hasStandardInvoicing`),
            },
            {
              type: "ValidatorSelect",
              path: `${product.productClassName}.subtractExtrajudicialProcessFee`,
              options: () => trueFalseDefaultOptions(`${product.productClassName}.subtractExtrajudicialProcessFee`),
              isDisabled: ({ product }: { product: AbstractCase }) =>
                product.stage! !== stagesCase.STAGE_LAWSUIT &&
                product.stage! !== stagesCase.STAGE_TRIAL &&
                product.stage! !== stagesCase.STAGE_ACTION_REQUIRED &&
                product.stage! !== stagesCase.STAGE_PREPARE_LAWSUIT,
            },
            {
              type: "ValidatorNumberField",
              path: `${product.productClassName}.netRvgAmount`,
              isDisabled: ({ product }: { product: AbstractCase }) =>
                product.stage! !== stagesCase.STAGE_LAWSUIT_COMPLETED || !product.lawsuitCompletedDate,
              isHidden: ({ product }: { product: AbstractCase }) => product.hasStandardInvoicing,
              fieldType: "money",
              numberType: "float",
            },
            {
              type: "ValidatorNumberField",
              path: `${product.productClassName}.costBearingByOpponentFinalAbsolute`,
              validators: [...invoiceNetValueNotOverpaid(`${product.productClassName}.netRvgAmount`)],
              isDisabled: ({ product }: { product: AbstractCase }) =>
                product.stage! !== stagesCase.STAGE_LAWSUIT_COMPLETED || !product.lawsuitCompletedDate,

              isHidden: ({ product }: { product: AbstractCase }) =>
                product.lawsuitCompletedReason === "lawsuitRevocation" || product.costBearingByOpponentRelative === 0.0,
              fieldType: "money",
              numberType: "float",
            },
            {
              type: "ValidatorNumberField",
              path: `${product.productClassName}.courtCostsFinal`,
              isDisabled: ({ product }: { product: AbstractCase }) =>
                product.stage! !== stagesCase.STAGE_LAWSUIT_COMPLETED || !product.lawsuitCompletedDate,

              isHidden: ({ product }: { product: AbstractCase }) =>
                product.lawsuitCompletedReason === "lawsuitRevocation" || product.costBearingByOpponentRelative === 0.0,
              numberType: "float",
              fieldType: "number",
            },
            {
              type: "ValidatorNumberField",
              path: `${product.productClassName}.costBearingByOpponentRelative`,
              validators: percentageFieldDefault,
              isDisabled: ({ product }: { product: AbstractCase }) =>
                !_.includes(
                  [stagesCase.STAGE_LAWSUIT_COMPLETED, stagesCase.STAGE_TRIAL, stagesCase.STAGE_LAWSUIT],
                  product.stage
                ),
              fieldType: "percentage",
              numberType: "float",
              isHidden: ({ product }: { product: AbstractCase }) =>
                product.lawsuitCompletedReason === "lawsuitRevocation",
            },
            {
              type: "ValidatorNumberField",
              path: `${product.productClassName}.principalClaim`,
              isHidden: ({ product, values }: { product: AbstractCase; values: UseForm["values"] }) =>
                values[product.productClassName].caseCategory !== "enforceClaim" ||
                product.lawsuitCompletedReason === "lawsuitRevocation" ||
                product.costBearingByOpponentRelative === 0.0,
              isDisabled: ({ product }: { product: AbstractCase }) => product.stage! !== stagesCase.STAGE_LAWSUIT,
              fieldType: "money",
              numberType: "float",
            },
            {
              type: "ValidatorDateField",
              path: `${product.productClassName}.principalClaimDefaultDate`,
              isHidden: ({ values }: { values: UseForm["values"] }) =>
                values[product.productClassName].caseCategory !== "enforceClaim" ||
                product.lawsuitCompletedReason === "lawsuitRevocation" ||
                product.costBearingByOpponentRelative === 0.0,
              isDisabled: ({ product }: { product: AbstractCase }) => product.stage! !== stagesCase.STAGE_LAWSUIT,
              validators: dateFieldDefault,
            },
            {
              type: "ValidatorNumberField",
              path: `${product.productClassName}.accessoryClaim`,
              isHidden: ({ values }: { values: UseForm["values"] }) =>
                values[product.productClassName].caseCategory !== "enforceClaim" ||
                product.lawsuitCompletedReason === "lawsuitRevocation" ||
                product.costBearingByOpponentRelative === 0.0,
              isDisabled: ({ product }: { product: AbstractCase }) => product.stage! !== stagesCase.STAGE_LAWSUIT,
              fieldType: "money",
              numberType: "float",
            },
            {
              type: "ValidatorDateField",
              path: `${product.productClassName}.accessoryClaimDefaultDate`,
              isHidden: ({ values }: { values: UseForm["values"] }) =>
                values[product.productClassName].caseCategory !== "enforceClaim" ||
                product.lawsuitCompletedReason === "lawsuitRevocation" ||
                product.costBearingByOpponentRelative === 0.0,
              isDisabled: ({ product }: { product: AbstractCase }) => product.stage! !== stagesCase.STAGE_LAWSUIT,
              validators: dateFieldDefault,
            },
          ],
        },
        {
          label: product.courtAppointments.length === 1 ? "Gerichtstermin" : "Gerichtstermine",
          isHidden: ({ product }: { product: AbstractCase }) => product.courtAppointments.length === 0,
          elements: getCourtAppointmentFields(product),
        },
        getNextInstanceSection(product),
      ],
    },
    processParticipants: {
      sections: [contractPartner, contractInsurance, debtCollection, opponentLawyer, prosecution],
    },
    additionalProcessParticipants: {
      sections: [processParticipantsPeople, processParticipantsOrganizations],
      withoutSubmit: true,
    },
    court: {
      sections: [
        {
          elements: [
            {
              type: "CourtPicker",
              path: `${product.productClassName}.responsibleCourt`,
              isDisabled: ({ product }: { product: AbstractCase }) =>
                (product.caseCategory === "enforceClaim" && product.stage! < stagesCase.STAGE_ACTION_REQUIRED) ||
                hasActiveCourtAppointment(product.courtAppointments),
              additionalProps: {
                fullWidth: true,
                courtTypes: ["Amtsgericht", "Arbeitsgericht", "Landgericht", "Mahngericht", "Oberlandesgericht"],
              },
            },
            ...allDataPageCourtFields(product.productClassName, "responsibleCourt"),
          ],
        },
      ],
    },
  };
};

export const getCourtAppointmentFields = (product: AbstractCase) => {
  let courtAppointmentFields: FormElementPropsWithoutProduct[] = [];
  _.forEach(product.courtAppointments, (courtAppointment, index) => {
    courtAppointmentFields.push(
      {
        type: "ValidatorDateField",
        path: `${product.productClassName}.courtAppointments[${index}].courtDate.appointmentDate`,
        translationPath: "courtAppointment.courtDate.appointmentDate",
        isDisabled: () => true,
      },
      {
        type: "ValidatorTextField",
        path: `${product.productClassName}.courtAppointments[${index}].courtDate.appointmentTime`,
        translationPath: "courtAppointment.courtDate.appointmentTime",
        isDisabled: () => true,
      },
      {
        type: "ValidatorSelect",
        path: `${product.productClassName}.courtAppointments[${index}].courtDateType`,
        translationPath: "courtAppointment.courtDateType",
        options: () => [
          { value: "hearing" },
          { value: "hearingOfEvidence" },
          { value: "settlement" },
          { value: "trialEmploymentLaw" },
          //{ value: "trialTenancyLaw" },
          { value: "trialTrafficLaw" },
          { value: "divorce" },
        ],
        isDisabled: () => true,
      }
    );
  });
  return courtAppointmentFields;
};

export const getNextInstanceSection = (product: AbstractCase) => ({
  label: "Urteilsprüfung",
  isHidden: ({ product }: { product: AbstractCase }) => product.stage! < stagesCase.STAGE_EVALUATE_JUDGEMENT,
  elements: [
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.nextInstanceRecommendation`,
      isDisabled: ({ product }: { product: AbstractCase }) => product.stage! !== stagesCase.STAGE_EVALUATE_JUDGEMENT,
      options: () => [{ value: "appeal" }, { value: "noAppeal" }],
      alert: {
        onChange:
          "Mit der Auswahl einer Option wird sofort und automatisch eine Anfrage an die Mandantschaft gesendet. Daher bitte sicherstellen, dass die Empfehlung final ist.",
        onInitial:
          "Mit der Auswahl einer Option wird sofort und automatisch eine Anfrage an die Mandantschaft gesendet. Daher bitte sicherstellen, dass die Empfehlung final ist.",
      },
    },
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.nextInstanceDecisionClient`,
      helperText:
        "Achtung: Feld ist über Servicewelt durch Mandantschaft zu befüllen! Wenn dieses Feld von uns befüllt wird, " +
        "kann die Mandantschaft kein Feedback mehr abgeben. Daher nur befüllen, wenn mit Mandantschaft explizit abgesprochen.",
      alert: {
        onInitial:
          "Sie möchten den Wert im Feld “Entscheidung Mandantschaft nächste Instanz” erstmalig ändern. Dieses " +
          "Feld wird standardmäßig durch die Mandantschaft über eine Anfrage in der Servicewelt befüllt und sollte " +
          "nur im Ausnahmefall und nach ausdrücklicher Abstimmung mit der Mandantschaft manuell befüllt werden! " +
          "Mit der Auswahl einer Option und anschließendem Speichern wird der Fall in die Stage Rechtsstreit beendet " +
          "verschoben. Sofern Sie “Berufung einlegen” auswählen, wird ein neuer Fall angelegt und darin ggf. eine " +
          "Deckungsanfrage an die Versicherung gesendet. Daher bitte sicherstellen, dass die Entscheidung final ist.",
        onChange:
          "Sie möchten den Wert im Feld “Entscheidung Mandantschaft nächste Instanz” erstmalig ändern. Dieses " +
          "Feld wird standardmäßig durch die Mandantschaft über eine Anfrage in der Servicewelt befüllt und sollte " +
          "nur im Ausnahmefall und nach ausdrücklicher Abstimmung mit der Mandantschaft manuell befüllt werden! " +
          "Mit der Auswahl einer Option und anschließendem Speichern wird der Fall in die Stage Rechtsstreit beendet " +
          "verschoben. Sofern Sie “Berufung einlegen” auswählen, wird ein neuer Fall angelegt und darin ggf. eine " +
          "Deckungsanfrage an die Versicherung gesendet. Daher bitte sicherstellen, dass die Entscheidung final ist.",
      },
      isDisabled: ({ product }: { product: AbstractCase }) =>
        !_.includes([stagesCase.STAGE_LAWSUIT_COMPLETED, stagesCase.STAGE_EVALUATE_JUDGEMENT], product.stage),
      options: () => [{ value: "appeal" }, { value: "checkJudgement" }, { value: "noAppeal" }],
    },
    {
      type: "ValidatorDateField",
      validators: dateFieldDefault,
      path: `${product.productClassName}.promulgationOfJudgementDate`,
    },
    {
      type: "ValidatorDateField",
      validators: dateFieldDefault,
      path: `${product.productClassName}.judgementEebSentDate`,
    },
  ],
});

export const getAdditionalClientsForm = (product: AbstractCase) => ({
  label: "Weitere Mandantschaft",
  isHidden: () => false,
  elements: [
    {
      type: "AdditionalClientsForm",
      path: `${product.productClassName}.additionalClients`,
      additionalProps: {
        fullWidth: true,
      },
    },
  ],
});

export const getContractUnderageClientForm = (product: AbstractCase) => {
  return {
    label: "Minderjähriger Vertragspartner",
    elements: [
      {
        type: "ValidatorSelect",
        path: `${product.productClassName}.clientWasMinorAtContractDate`,
        options: () => trueFalseDefaultOptions(`${product.productClassName}.clientWasMinorAtContractDate`),
        additionalProps: {
          fullWidth: true,
        },
      },
      {
        type: "ValidatorSelect",
        path: `${product.productClassName}.clientLegalRepresentativeAmount`,
        options: () => [{ value: 1 }, { value: 2 }],
        isHidden: ({ values }: { values: UseForm["values"] }) =>
          values[product.productClassName].clientWasMinorAtContractDate !== true,
        additionalProps: {
          fullWidth: true,
        },
      },
      {
        type: "ValidatorSelect",
        path: `${product.productClassName}.clientLegalRepresentativesAuthorizationType`,
        options: () => [{ value: "both" }, { value: "individually" }],
        isHidden: ({ values }: { values: UseForm["values"] }) =>
          values[product.productClassName].clientWasMinorAtContractDate !== true ||
          values[product.productClassName].clientLegalRepresentativeAmount < 2,
        additionalProps: {
          fullWidth: true,
        },
      },
      ...personFields({
        path: `${product.productClassName}.clientLegalRepresentative`,
        isHidden: ({ values }: { values: UseForm["values"] }) =>
          values[product.productClassName].clientWasMinorAtContractDate !== true,
      }),
      ...addressFields({
        path: `${product.productClassName}.clientLegalRepresentative.residenceAddress`,
        isHidden: ({ values }: { values: UseForm["values"] }) =>
          values[product.productClassName].clientWasMinorAtContractDate !== true,
      }),
      ...personFields({
        path: `${product.productClassName}.secondClientLegalRepresentative`,
        isHidden: ({ values }: { values: UseForm["values"] }) => {
          return (
            values[product.productClassName].clientWasMinorAtContractDate !== true ||
            values[product.productClassName].clientLegalRepresentativeAmount < 2
          );
        },
      }),
      ...addressFields({
        path: `${product.productClassName}.secondClientLegalRepresentative.residenceAddress`,
        isHidden: ({ values }: { values: UseForm["values"] }) => {
          return (
            values[product.productClassName].clientWasMinorAtContractDate !== true ||
            values[product.productClassName].clientLegalRepresentativeAmount < 2
          );
        },
      }),
    ],
  };
};
