import personTranslations from "./personTranslations";
import basicCaseTranslations from "./basicCaseTranslations";
import { courtTranslations } from "./courtTranslations";
import { opponentLawFirmTranslations } from "./opponentLawFirmTranslations";
import opposingPartyLegalEntityTranslations from "./opposingPartyLegalEntityTranslations";

const litigationCaseTranslations = {
  ...basicCaseTranslations,
  advoAssistHearing: {
    label: "Advo Assist Termin",
  },
  opponentLawyer: personTranslations,
  referenceOpponentLawyer: {
    label: "Aktenzeichen Anwalt Gegenseite",
  },
  opponentLawFirm: opponentLawFirmTranslations,
  objectiveCommitted: {
    label: "Zielsetzung Gerichtsverfahren",
    values: {
      fulfillment: "Erfüllung",
      withdrawal: "Rücktritt",
      reduction: "Erstattung",
      compensation: "Schadensersatz",
      repair: "Nacherfüllung",
      replacement: "Ersatz",
    },
  },
  statementOfClaimDocument: {
    label: "Klageschrift",
  },
  judgementReceivedDate: {
    label: "Eingang Urteil am",
  },
  opposingPartyReference: {
    label: "Aktenzeichen Ansprechpartner Gegenseite",
  },
  nextInstanceRecommendation: {
    label: "Empfehlung Kanzlei nächste Instanz",
    values: {
      appeal: "Rechtsmittel einlegen",
      noAppeal: "Keine Rechtsmittel einlegen",
    },
  },
  nextInstanceDecisionClient: {
    label: "Entscheidung Mandantschaft nächste Instanz",
    values: {
      appeal: "Berufung einlegen",
      noAppeal: "Keine Berufung einlegen",
      checkJudgement: "Empfehlung einholen",
    },
  },
  statementOfClaimSentDate: {
    label: "Klage eingereicht am",
  },
  promulgationOfJudgementDate: {
    label: "Verkündungsdatum des Urteils",
  },
  judgementEebSentDate: {
    label: "EB-Datum Urteil",
  },
  statementOfClaimFiledDate: {
    label: "Klage erhalten am",
  },
  statementOfDefenseDocument: {
    label: "Klageerwiderung eingereicht am",
  },
  statementOfDefenseSentDate: {
    label: "Klageerwiderung",
  },
  statementOfDefenseFiledDate: {
    label: "Klageerwiderung erhalten am",
  },
  replicaDocument: {
    label: "Replik",
  },
  replicaSentDate: {
    label: "Replik eingereicht am",
  },
  replicaFiledDate: {
    label: "Replik erhalten am",
  },
  customerLawsuitCompletedApprovalDate: {
    settlement: {
      label: "Zustimmung Mandant zum Vergleich am",
    },
    lawsuitRevocation: {
      label: "Zustimmung Mandant zur Klagerücknahme am",
    },
    resolution: {
      label: "Zustimmung Mandant zur Erledigungserklärung am",
    },
    //cause the validation cant access form values
    label: "Datum Zustimmung Gerichtsverfahrensende",
  },
  lawsuitCompletedReason: {
    label: "Rechtsstreit beendet durch",
    values: {
      courtDecision: "Urteil",
      settlement: "Vergleich",
      lawsuitRevocation: "Klagerücknahme",
      resolution: "Erledigung",
      defaultJudgement: "Versäumnisurteil",
      judgementAppeal: "Einlegung von Rechtsmitteln",
    },
  },
  lawsuitCompletedDate: {
    label: "Gerichtsverfahren beendet am",
  },
  valueInDisputePreliminary: {
    label: "vorläufiger Streitwert",
  },
  valueInDispute: {
    label: "Streitwert",
  },
  hasStandardInvoicing: {
    label: "Standardabrechnung?",
    values: {
      true: "Ja",
      false: "Nein",
    },
  },
  subtractExtrajudicialProcessFee: {
    label: "Anrechnung außergerichtliches Verfahren",
    values: {
      true: "Ja",
      false: "Nein",
    },
  },
  netRvgAmount: {
    label: "Gesamtnettobetrag RVG Rechnung",
  },
  costBearingByOpponentFinalAbsolute: {
    label: "Gezahlte Kosten brutto durch Gegner exkl. GK",
  },
  costBearingByOpponentRelative: {
    label: "Kostenanteil Gegner laut Quotelung im Urteil in %",
  },
  courtCostsFinal: {
    label: "Gerichtskosten laut Beschluss",
  },
  principalClaim: {
    label: "Hauptforderung",
  },
  principalClaimDefaultDate: {
    label: "Hauptforderung im Verzug seit",
  },
  accessoryClaim: {
    label: "Nebenforderung",
  },
  accessoryClaimDefaultDate: {
    label: "Nebenforderung im Verzug seit",
  },
  courtPostalCode: {
    label: "PLZ Gerichtsstand",
  },
  courtReference: {
    label: "Aktenzeichen Gericht",
  },
  responsibleCourt: courtTranslations,
  clientBankData: {
    label: "Bankverbindung Mandant",
    iban: {
      label: "IBAN für Rückerstattung",
    },
  },
  caseInSuspension: {
    label: "Fall in Aussetzung",
    values: {
      true: "Ja",
      false: "Nein",
    },
  },
  automaticallyGenerateExtrajudicialLetter: {
    label: "Automatisches Schreiben verwenden?",
  },
  defenseProcessStatus: {
    label: "Status der Forderung gegen Mandantschaft",
    values: {
      none: "Noch nichts erhalten",
      reminder: "Zahlungserinnerung / Mahnung",
      debtCollection: "Inkasso eingeschaltet",
      paymentOrder: "Mahnbescheid erhalten",
      enforcementOrder: "Vollstreckungsbescheid erhalten",
      lawsuit: "Klage erhalten",
    },
  },
  extrajudicialReaction: {
    label: "Reaktion der Gegenseite",
    values: {
      noReaction: "Keine Reaktion",
      fullyAgreed: "Forderung wird vollständig erfüllt/aufgegeben",
      partiallyAgreed: "Forderung wird teilweise erfüllt/aufgegeben",
      disagreed: "Forderung wird nicht erfüllt/aufrechterhalten",
    },
    enforceClaim: {
      label: "Reaktion der Gegenseite",
      values: {
        noReaction: "Keine Reaktion",
        fullyAgreed: "Forderung wird voll erfüllt",
        partiallyAgreed: "Forderung wird teilweise erfüllt",
        disagreed: "Forderung wird nicht erfüllt",
      },
    },
    defendClaim: {
      label: "Reaktion der Gegenseite",
      values: {
        noReaction: "Keine Reaktion",
        fullyAgreed: "Forderung wird vollständig aufgegeben",
        partiallyAgreed: "Forderung wird teilweise aufgegeben",
        disagreed: "Forderung wird aufrechterhalten",
      },
    },
  },
  extrajudicialRecommendation: {
    label: "Empfehlung weiteres Vorgehen",
    values: {
      agreementDone: "Einigung erzielt / Forderung erledigt",
      lawsuit: "Klage einreichen / Klage abwarten",
      insufficientEvidence: "Schlechte Beweislage",
      insufficientChancesOfSuccess: "Schlechte Erfolgsaussicht",
      costBenefitConsiderations: "Schlechtes Kosten-Nutzen-Verhältnis",
      anonymousOpponent: "Gegenseite unklar",
      opponentUnableToPay: "Gegenseite nicht solvent",
      individualText: "Individuelle Empfehlung",
    },
  },
  extrajudicialRecommendationText: {
    label: "Zusatztext Empfehlung",
  },
  finalClaimStatusClient: {
    label: "Stand Forderung aus Sicht Mandantschaft",
    values: {
      fullyAgreed: "Voll erledigt",
      partiallyAgreed: "Teilweise erledigt",
      notAgreed: "Nicht erledigt",
      noReaction: "Keine erledigt",
    },
    enforceClaim: {
      label: "Stand Forderung aus Sicht Mandantschaft",
      values: {
        fullyAgreed: "Voll erfüllt",
        partiallyAgreed: "Teilweise erfüllt",
        notAgreed: "Nicht erfüllt",
        noReaction: "Keine Reaktion",
      },
    },
    defendClaim: {
      label: "Stand Forderung aus Sicht Mandantschaft",
      values: {
        fullyAgreed: "Forderung durch Gegenseite aufgegeben",
        partiallyAgreed: "Forderung durch Gegenseite teilweise aufgegeben",
        notAgreed: "Forderung durch Gegenseite nicht aufgegeben",
        noReaction: "Keine Reaktion",
      },
    },
  },
  finalProcessFeedbackClient: {
    label: "Finale Entscheidung Mandantschaft",
    values: {
      agreement: "Fall abschließen",
      lawsuit: "Gerichtsverfahren",
    },
    enforceClaim: {
      label: "Finale Entscheidung Mandantschaft",
      values: {
        agreement: "Forderung erledigt/zurücknehmen",
        lawsuit: "Forderung einklagen",
      },
    },
    defendClaim: {
      label: "Finale Entscheidung Mandantschaft",
      values: {
        lawsuit: "Forderung nicht erfüllen und Klage abwarten",
        agreement: "Forderung erfüllen und Klage vermeiden",
      },
    },
  },
  extrajudicialReactionReceived: {
    label: "Reaktion der Gegenseite am",
  },
  timeBarredDate: {
    label: "Fall verjährt am",
  },
  extrajudicialCommunicationSent: {
    label: "Schreiben an Gegenseite am",
  },
  extrajudicialCommunicationDeadline: {
    label: "Gesetzte Frist für Gegenseite",
  },
  opposingPartyType: {
    label: "Gegenseite ist",
    values: {
      opposingPartyLegalEntity: "Unternehmen",
      opposingPartyPerson: "Privatperson",
    },
  },
  opposingPartyPerson: personTranslations,
  opposingPartyLegalEntity: {
    label: "Gegenseite",
    ...opposingPartyLegalEntityTranslations,
  },
};

export default litigationCaseTranslations;
